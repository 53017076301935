<template>
    <div class="_wrap">

        <div class="_banner">

            <div class="_cont">
                <div class="_left _veList">

                    <div class="swiper mySwiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item, index) in topVideo" :key="index"
                                @click="lookVideo(item)">
                                <img :src="$IMG_URL + item.cover">
                            </div>

                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>


                <div class="_right">
                    <h4 v-if="topVideo.length > 0">{{ topVideo[current - 1].title }}</h4>
                    <div class="_xian"></div>
                    <div class="_sub" v-if="topVideo.length > 0">{{ topVideo[current - 1].description }}</div>
                    <div class="swiper-pagination">
                        <span class="dangqian"></span>
                        <span style="font-size: 24px;">/</span>
                        <span class="zongshu"></span>

                    </div>

                </div>

            </div>
        </div>




        <div class="_spmit">

            <div class="_auto">
                <h4>视觉八办</h4>


                <div class="_list">
                    <div class="_li" v-for="( item, index ) in  list " :key="index" @click="lookVideo(item)">
                        <div class="_img">
                            <img class="_mainImg" :src="$IMG_URL + item.cover" alt="">
                            <img class="_bf" src="../../assets/image/home-img/bf.png" alt="">
                        </div>

                        <p>
                            {{ item.title }}
                        </p>
                    </div>
                </div>



                <div class="_page">
                    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
                        :limit.sync="queryParams.page_num" @pagination="getList" />

                </div>

            </div>
        </div>


        <div class="_fixed">
            <div class="_div">
                <img src="../../assets/image/home-img/bg_douyin.png" alt="">
                <p>官方抖音号</p>
            </div>
            <div class="_div">
                <img src="../../assets/image/home-img/bg_shipinhao.png" alt="">
                <p>微信视频号</p>
            </div>
        </div>


        <mainFooter></mainFooter>

        <dieVideo ref="dieVideoRef"></dieVideo>
    </div>
</template>

<script>
import Swiper from 'swiper'
import {videoList, videoHits } from "@/api/getData"
import dieVideo from '../../components/diaVideo/dieVideo.vue'
export default {
    components: {
        dieVideo,
    },
    data() {
        return {
            current: 1,
            total: 0,
            queryParams: {
                page: 1,
                page_num: 15,
                type: 2
            },

            topVideo: [],
            list: [],

        }
    },
    async mounted() {
        await this.getList()
        this.initSwiper()


        window.scrollTo({
            top: 0,
            behavior: 'smooth' // 平滑滚动
        })

    },
    methods: {
        initSwiper() {
            let _this = this
            var swiper = new Swiper(".mySwiper", {
                pagination: {
                    el: ".swiper-pagination",
                    type: "custom",
                    renderCustom: function (swiper, current, total) {
                        var dangqian = document.querySelector(".dangqian")
                        var zongshu = document.querySelector(".zongshu")
                        _this.current = current
                        if (current <= 9) {
                            current = "0" + current
                        } else {
                            current = current
                        }
                        if (total <= 9) {
                            total = "0" + total
                        } else {
                            total = total
                        }
                        dangqian.innerText = current
                        zongshu.innerText = total
                    }

                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        },

       
        async getList() {
           await videoList(this.queryParams).then(res => {
                console.log(res)
                this.list = res.data.list.data
                this.total = res.data.list.total
                this.topVideo = res.data.top

            })
        },

        lookVideo(item) {
            console.log(item)
            const params = {
                id: item.id
            }
            videoHits(params).then(res => {
                this.$refs.dieVideoRef.edit(item)

            })

        },
    },

}
</script>

<style lang="scss" scoped>

@import "../../common/mixin.scss";

._banner
{
    width: 100%;
    height: 884px;
    position: relative;
    overflow: hidden;

    background-image: url("../../assets/image/video-img/bg_shipin_banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    ._cont
    {
        width: 67%;
        margin: 0 auto;
        margin-top: 252px;
        display: flex;

        ._left
        {
            width: 760px;
            position: relative;

            .mySwiper
            {
                width: 720px;
                height: 409px;
                margin: 0 auto;
                overflow: hidden;
                border: 2px solid #FFE9BD;
               
                .swiper-slide img
                {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    cursor: pointer;
                    
                }
            }
        }

        ._right
        {
            flex: 1;
            margin-left: 140px;
            position: relative;

            h4
            {
                width: 100%;
                font-size: 36px;
                color: white;
                letter-spacing: 1px;
                position: relative;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* 设置最大显示行数 */
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;

            }

            ._xian
            {
                width: 100%;
                height: 1px;
                background: rgba(255, 255, 255, 0.3);
                margin-top: 40px;
            }



            ._sub
            {
                color: #D7D7D7;
                margin-top: 33px;
                font-size: 20px;
                letter-spacing: 1px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                /* 设置最大显示行数 */
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

        }
    }


}

._page
{
    text-align: center;

}

._spmit
{
    margin-bottom: 60px;

    ._auto
    {
        width: 75%;
        margin: 0 auto;
    }

    h4
    {
        color: #333;
        font-size: 36px;
        position: relative;
        text-align: center;
    }

    h4::before
    {
        content: '';
        display: inline-block;
        width: 50px;
        height: 1px;
        background: #B41E1E;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-25px);
    }

    ._list
    {
        margin-top: 68px;
        display: flex;
        flex-wrap: wrap;

        ._li
        {
            width: 30.5%;
            margin-right: 3.9%;
            cursor: pointer;
            margin-bottom: 60px;

            ._img
            {
                width: 100%;
                height: 272px;
                overflow: hidden;
                position: relative;

                ._mainImg
                {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s;
                }

                ._mainImg:hover
                {
                    transform: scale(1.1);
                }

                ._bf
                {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -24px;
                    margin-left: -24px;
                }
            }

            p
            {
                color: #333;
                font-size: 20px;
                margin-top: 18px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        ._li:nth-child(3n)
        {
            margin-right: 0;
        }
    }
}

._fixed
{
    position: fixed;
    top: 40%;
    right: 0;
    z-index: 9;

    ._div
    {
        width: 120px;
        height: 131px;
        background: rgba(0, 0, 0, 0.3);
        margin-bottom: 10px;
        text-align: center;
        color: white;

        img
        {
            width: 81px;
            margin-top: 13px;
        }
    }
}



::v-deep ._veList .swiper-button-prev
{
    background-image: url("../../assets/image/video-img/icon_shipin_left.png");
    background-size: 100 100%;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    top: 50%;
    left: -55px;
    z-index: 20;
}

::v-deep ._veList .swiper-button-prev:after
{
    content: '';
}

::v-deep ._veList .swiper-button-next
{
    background-image: url("../../assets/image/video-img/icon_shipin_right.png");
    background-size: 100 100%;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    top: 50%;
    right: -55px;
    z-index: 20;



}

::v-deep ._veList .swiper-button-next:after
{
    content: '';
}


::v-deep ._right .swiper-pagination
{
    position: absolute;
    text-align: center;
    width: 166px;
    height: 60px;
    color: white;
    border: 1px solid #fff;
    line-height: 60px;
    bottom: 0;

}

::v-deep ._right .dangqian
{
    font-size: 54px;
    margin-right: 10px;
}

::v-deep ._right .zongshu
{
    font-size: 24px;

}


@include respondTo('phone'){
    ._fixed{
        display: none;
    }
    ._banner{
        height: 660px;
        background-size: cover;

        ._cont{
            margin-top: 100px;
            display: block;
            width: 100%;
            ._left{
               width: 100%;
               padding: 0 8px;
                .mySwiper{
                    width: 100%;
                    height: 246px;


                    .swiper-button-prev{
                        width: 35px;
                        height: 35px;
                        left: 15px;
                        background-size:cover;
                    }
                    .swiper-button-next{
                        width: 35px;
                        height: 35px;
                        right: 15px;
                        background-size:cover;


                    }
                }
            }

            ._right{
                margin-left: 0;
                padding: 0 8px;
                margin-top: 20px;
                height: 240px;
                h4{
                    font-size: 20px;
                }
                ._xian{
                    margin-top: 10px;
                }
                ._sub{
                    margin-top: 10px;
                }

                .swiper-pagination{
                    left: 50%;
                    transform: translateX(-83px);
                }
            }
        }
    }

    ._spmit{
        margin-top: 20px;
        margin-bottom: 40px;
        ._auto{
            padding: 0 8px;
            h4{
                font-size: 24px;
            }
            h4::before{
                bottom: -5px;
            }
        }

        ._list{
            margin-top: 20px;
            display: block;
            ._li{
                margin-right: 0;
                width: 100%;
                margin-bottom: 20px;
                p{
                    font-size: 18px;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>